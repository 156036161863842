
import {
  ComponentPublicInstance,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "FastOrderNavigation",
  components: { Form, Field },
  setup() {
    const validationSchema = Yup.object().shape({
      order_id: Yup.number().optional().label("Order ID"),
    });

    const input = ref<ComponentPublicInstance | null>(null);

    const handleKeyUp = (e) => {
      const key = e.keyCode || 0;
      if (
        ((key >= 48 && key <= 57) || // 0-9 digits
          (key >= 96 && key <= 105)) && // numpad 0-9
        (e.target == null ||
          ["INPUT", "TEXTAREA"].indexOf(e.target?.nodeName) === -1)
      ) {
        input.value?.$el.focus();
      }
    };

    const router = useRouter();

    const onSubmit = (values, { resetForm }) => {
      resetForm();
      router.push({
        name: "orders-view",
        params: { id: values.order_id },
      });
    };

    onMounted(() => {
      window.addEventListener("keydown", handleKeyUp);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("keydown", handleKeyUp);
    });

    return {
      validationSchema,
      onSubmit,
      input,
    };
  },
});
