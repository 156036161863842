<template>
  <div class="row justify-content-end pb-4">
    <div class="col col-12 col-md-3">
      <fast-order-navigation />
    </div>
  </div>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FastOrderNavigation from "@/modules/common/widgets/FastOrderNavigation/FastOrderNavigation.vue";

export default defineComponent({
  name: "OrdersRouter",
  components: { FastOrderNavigation },
});
</script>

<style scoped></style>
